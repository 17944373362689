import React from 'react';
import '../assets/css/main.css'
import base_logo from '../assets/images/baselogo_stacked.png'
import screenshot from '../assets/images/wrkrs_screenshots.png'


class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false
    };
  }
  
  render() {     
    var image = this.props.image  
    
    return (
      <React.Fragment>
        <div>          
          <div className="welcome_container">
              <img className="welcomeImage" src={base_logo} />
              <img className="screenshotImage" src={screenshot} />
              <div className="welcomeText">
                <h3>Automated labour hire and human resource management.</h3>
                <h3 style={{fontWeight:"bolder"}}>COMING SOON</h3>
              </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default Welcome
